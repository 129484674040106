import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container, Typography, TextField, Button, CircularProgress, List, ListItem,
    Link, Paper, Box, Grid, MenuItem, Select
} from '@mui/material';
import { Upload, FileDownload } from '@mui/icons-material';
import config from './config';

function App() {
    const [contentType, setContentType] = useState('text');
    const [content, setContent] = useState('');
    const [file, setFile] = useState(null);
    const [sharedContent, setSharedContent] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchSharedContent = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${config.API_BASE_URL}/api/shared-content`);
                setSharedContent(response.data);
            } catch (error) {
                console.error('Error fetching shared content', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSharedContent();
    }, []);

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('content_type', contentType);

        if (contentType === 'file') {
            formData.append('file', file);
        } else {
            formData.append('content', content);
        }

        try {
            await axios.post(`${config.API_BASE_URL}/api/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert('Content uploaded successfully');
            const response = await axios.get(`${config.API_BASE_URL}/api/shared-content`);
            setSharedContent(response.data);
        } catch (error) {
            console.error('Error uploading content', error);
        }
    };

    return (
        <Container maxWidth="md" style={{ marginTop: '40px' }}>
            <Box textAlign="center" mb={4}>
                <Typography variant="h3" gutterBottom>
                    WireShare
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    Share text, links, or files easily with anyone.
                </Typography>
            </Box>

            <Paper elevation={3} style={{ padding: '30px', marginBottom: '40px' }}>
                <Typography variant="h5" gutterBottom>
                    Upload Content
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Select
                            value={contentType}
                            onChange={(e) => setContentType(e.target.value)}
                            fullWidth
                        >
                            <MenuItem value="text">Text</MenuItem>
                            <MenuItem value="link">Link</MenuItem>
                            <MenuItem value="file">File</MenuItem>
                        </Select>
                    </Grid>

                    {contentType !== 'file' ? (
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label={`Enter ${contentType === 'text' ? 'Text' : 'Link'}`}
                                fullWidth
                                margin="normal"
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <input
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                                style={{ margin: '20px 0', display: contentType === 'file' ? 'block' : 'none' }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUpload}
                            startIcon={<Upload />}
                            fullWidth
                        >
                            Upload
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Typography variant="h5" gutterBottom>
                Shared Content
            </Typography>
            {loading ? (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            ) : (
                <List>
                    {sharedContent.map((item) => (
                        <ListItem key={item.id}>
                            {item.content_type === 'file' ? (
                                <Link
                                    href={`${config.API_BASE_URL}/storage/${item.content}`}
                                    target="_blank"
                                    download
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: '#007bff' }}
                                >
                                    <Button
                                        variant="outlined"
                                        startIcon={<FileDownload />}
                                        fullWidth
                                    >
                                        Download File
                                    </Button>
                                </Link>
                            ) : item.content_type === 'link' ? (
                                <Link
                                    href={item.content}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color: '#007bff' }}
                                >
                                    {item.content}
                                </Link>
                            ) : (
                                <Typography>{item.content}</Typography>
                            )}
                        </ListItem>
                    ))}
                </List>
            )}
        </Container>
    );
}

export default App;
